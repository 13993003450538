export default class Analyser {

    // Search Event
    
    static search(search_string) {
        this.firePixelStandardEvent('Search', {
            search_string: search_string,
        })

        const data = {
            'search_term': search_string,
        }
        this.fireGoogleAnalyticsStandardEvent('search', data) 
        this.fireGoogleTagManagerEvent('search', data) 
    }

    static viewContent() {
        ViewContent
    }



    static addToCart(item, quantity, price) {
        this.firePixelStandardEvent('AddToCart', {
            content_type: 'product',
            content_ids: [`${item.catalogue_id}`],
            content_name: item.display_name,
            contents: [{'id': item.product_id, 'sku': item.product_sku_name ?? "", 'quantity': quantity}],
            value: price,
            currency: 'NZD'
        })

        const data = {
            'items': [
                {
                    'id': item.product_id,
                    'name': item.display_name,
                    'variant': item.product_sku_name ?? "",
                    'quantity': quantity,
                    'price': price,
                },
            ],
            'value': price,
            'currency': 'NZD',
        }
        this.fireGoogleAnalyticsStandardEvent('add_to_cart', data) 
        this.fireGoogleTagManagerEvent('add_to_cart', data) 

        const dynamicRemarketingData = {
            'ecomm_pagetype': 'cart',
            'ecomm_totalvalue': price,
            'currency': 'NZD',
            'ecomm_prodid': [item.product_id.toString()],
        }

        this.fireGoogleTagManagerEvent('DynamicRemarketing', dynamicRemarketingData) 
    }

    static removeFromCart(item, quantity, price) {

        this.firePixelCustomEvent('RemoveFromCart', {
            content_type: 'product',
            content_ids: [`${item.catalogue_id}`],
            content_name: item.display_name,
            contents: [{'id': item.product_id, 'sku': item.product_sku_name ?? "", 'quantity': quantity}],
            value: price,
            currency: 'NZD'
        })
        
        const data =  {
            'currency': 'NZD',
            'value': price,
            'items': [
                {
                    'id': item.product_id,
                    'name': item.display_name,
                    'variant': item.product_sku_name ?? "",
                    'quantity': quantity,
                    'price': price,
                },
            ],
        }
        this.fireGoogleAnalyticsStandardEvent('remove_from_cart', data) 
        this.fireGoogleTagManagerEvent('remove_from_cart', data) 
    }

    static beginCheckout(items) {
        
        this.firePixelStandardEvent('InitiateCheckout', {
            content_name: 'Checkout',
            contents: items.map((item) => {
                return {
                  id: item.product_id,
                  name: item.display_name,
                  sku: item.product_sku_name ?? "",
                  quantity: item.quantity,
                  price: parseFloat((item.price / 100).toFixed(2)),
                };
            }),
            num_items: items.reduce((total, item) => total += item.quantity ,0),
            value: parseFloat((items.reduce((total, item) => total += (item.price * item.quantity ) ,0) / 100).toFixed(2)),
            currency: 'NZD'
        })

        const data = {
            'currency': 'NZD',
            'value': (items.reduce((total, item) => total += (item.price * item.quantity ) ,0) / 100).toFixed(2),
            'items': items.map((item) => {
                return {
                  'id': item.product_id,
                  'name': item.display_name,
                  'variant': item.product_sku_name ?? "",
                  'quantity': item.quantity,
                  'price': (item.price / 100).toFixed(2),
                };
            }),
            'coupon': "",
        }

        this.fireGoogleAnalyticsStandardEvent('begin_checkout', data) 
        this.fireGoogleTagManagerEvent('begin_checkout', data) 
    }

    static addToWishList(product, price) {
        this.firePixelStandardEvent('AddToWishlist', {
            content_name: item.product_name,
            contents: [{'id': product.id, 'name': product.name, 'quantity': 1}],
            value: price,
            currency: 'NZD'
        })

        const data = {
            'currency': 'NZD',
            'value': price,
            'items': [{
                'item_id': product.id,
                'item_name': product.name,
                'item_brand': product.brand?.name ?? '',
                'item_category': product.categories[0].name,
            }]
        }

        this.fireGoogleAnalyticsStandardEvent('add_to_wishlist', data) 
        this.fireGoogleTagManagerEvent('add_to_wishlist', data) 
    }

    static purchase(customer_order, cart) {
        this.firePixelStandardEvent('Purchase', {
            contents: cart.items.map((item) => {
                return {
                  id: item.product_id,
                  name: item.display_name,
                  sku: item.product_sku_name ?? "",
                  quantity: item.quantity,
                  price: parseFloat((item.price / 100).toFixed(2)),
                };
              }),
            value: parseFloat((customer_order.sub_total / 100).toFixed(2)),
            currency: 'NZD'
        })

        const data = {
            'currency': 'NZD',
            'transaction_id': customer_order.id,
            'value': (customer_order.sub_total / 100).toFixed(2),
            'shipping': customer_order.shipping_amount,
            'items': cart.items.map((item) => {
              return {
                'id': item.product_id,
                'name': item.display_name,
                'variant': item.product_sku_name ?? "",
                'quantity': item.quantity,
                'price': (item.price / 100).toFixed(2),
              };
            }),
        }

        this.fireGoogleAnalyticsStandardEvent('purchase', data) 
        this.fireGoogleTagManagerEvent('purchase', data) 

        const dynamicRemarketingData = {
            'ecomm_pagetype': 'purchase',
            'ecomm_totalvalue': (customer_order.sub_total / 100).toFixed(2),
            'order_id': customer_order.id.toString(),
            'currency': 'NZD',
            'user_email': customer_order.contact.email,
            'user_phone': customer_order.contact.phone ?? customer_order.contact.mobile ?? '',
            'order_user_first_name': customer_order.first_name,
            'order_user_last_name': customer_order.last_name,
            'order_shipping_street': customer_order.contact.street,
            'order_shipping_city': customer_order.contact.city,
            'order_shipping_region': customer_order.contact.state ?? '',
            'order_shipping_postal_code': customer_order.contact.postcode,
            'order_shipping_country_code': 'NZ',
            'ecomm_prodid': cart.items.map((item) => item.product_id.toString()),
        }

        this.fireGoogleTagManagerEvent('DynamicRemarketing', dynamicRemarketingData) 
    }


    // Event Calls

    static firePixelStandardEvent(event, data) {
        if (typeof fbq == 'function') {
            fbq( 'track', event, data );
        }
    }

    static firePixelCustomEvent(event, data) {
        if (typeof fbq == 'function') {
            fbq( 'trackCustom', event, data );
        }
    }

    static fireGoogleAnalyticsStandardEvent(event, data) {
        if (typeof gtag == 'function') {
            gtag("event", event, data);
        }  
    }

    static fireGoogleTagManagerEvent(event, data) {
        if (window.dataLayer?.some(e => e['gtm.start'])) {
            dataLayer.push({
                'event': event,
                ...data
            })
        }
    }
}